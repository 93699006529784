// This is the base for splide carousel, the full documentation can be found on https://splidejs.com/events/
// The Grid extension is just used when the carousel display are not in line but on a group,
// So the carousel changes the whole group of elements and not one by one.
// The grid extensions and functions can be deleted if it's not in the website design.

import Splide from '@splidejs/splide';
import { merge } from 'lodash';
import { Grid } from '@splidejs/splide-extension-grid';

const bootstrapBreakpoints = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1400,
};

let mainSplider, thumbnailSplider;

const splideArrowPath =
	'M31.8792 19.5517L15.6292 9.15107C15.4801 9.00303 15.258 8.96026 15.0609 9.03846C14.8659 9.11979 14.7398 9.30957 14.7398 9.52018V15.2548L7.52135 15.2548C7.23357 15.2549 7 15.4884 7 15.7762V24.1176C7 24.4053 7.23357 24.6389 7.52135 24.6389L14.7398 24.6389V30.3517C14.7398 30.5623 14.867 30.7521 15.062 30.8334C15.1266 30.8605 15.1944 30.873 15.2612 30.873C15.3967 30.873 15.5302 30.8199 15.6303 30.7208L31.8802 20.2899C31.9782 20.1919 32.0325 20.0594 32.0325 19.9208C32.0324 19.7821 31.9772 19.6497 31.8792 19.5517Z';

const splideConfiguration = {
	default: {
		arrowPath: splideArrowPath,
		type: 'loop',
		start: 0,
		perPage: 3,
		pagination: true,
		focus: 1,
		mediaQuery: 'min',
		breakpoints: {
			640: {
				perPage: 3,
			},
		},
	},
	'card-deck': {
		arrowPath: splideArrowPath,
		type: 'loop',
		start: 0,
		perPage: 1,
		pagination: true,
		focus: 'center',
		padding: '5rem',
		mediaQuery: 'min',
		breakpoints: {
			[ bootstrapBreakpoints.sm ]: {
				perPage: 3,
			},
			[ bootstrapBreakpoints.md ]: {
				perPage: 3,
			},
		},
	},
	'logos-carousel': {
		type: 'loop',
		start: 0,
		perPage: 2,
		perMove: 1,
		focus: 0,
		grid: {
			rows: 2,
			cols: 1,
		},
		pagination: true,
		arrows: false,
		mediaQuery: 'min',
		breakpoints: {
			320: {
				destroy: false,
			},
			768: {
				destroy: false,
			},
			992: {
				destroy: false,
			},
			993: {
				destroy: true,
			},
		},
	},
	'testimonial-carousel': {
		updateOnMove: true,
		type: 'loop',
		start: 0,
		focus: 0,
		drag: false,
		perPage: 2.5,
		gap: '2rem',
		pagination: false,
		arrowPath: splideArrowPath,
		arrows: true,
		breakpoints: {
			992: {
				perPage: 1.5,
				focus: 0,
				gap: '2em',
			},

			768: {
				perPage: 1,
				focus: 0,
				gap: '2em'
			}
		},
	},
	'text-carousel': {
		arrowPath: splideArrowPath,
		type: 'loop',
		start: 0,
		perPage: 1,
		perMove: 1,
		focus: 0,
		pagination: true,
		arrows: true,
		breakpoints: {
			640: {
				perPage: 1,
				focus: 0,
			},
		},
	},

	'text-icon-carousel': {
		type: 'loop',
		start: 0,
		arrows: true,
		arrowPath: splideArrowPath,
		pagination: false,
		cover: true,
		interval: 5000,
		thumbnailSettings: {
			pagination: false,
			isNavigation: true,
			drag: false,
			fixedWidth: 70,
			fixedHeight: 70,
			arrows: false,
			gap: 50,
			breakpoints: {
				480: {
					fixedWidth: '100%',
					fixedHeight: '100%',
					drag: true,
					perPage: 1,
				},
			},
		},
	},
};

function initializeSplide() {
	const splides = document.getElementsByClassName( 'splide' );
	[ ...splides ].forEach( ( splide ) => {
		let config = splideConfiguration.default;

		const configAttr = splide.getAttribute( 'data-splide-config' );
		if ( configAttr !== undefined && configAttr !== '' ) {
			config = splideConfiguration[ configAttr ];
		}

		// Check for disable settings
		const disableAttr = splide.getAttribute( 'data-splide-disable' );
		if ( disableAttr !== null && disableAttr !== '' ) {
			const disableBreakpoints = disableAttr.split( ',' );

			// Track if a breakpoint has been disabled.
			let disableFlag = false;

			for ( const breakpoint in bootstrapBreakpoints ) {
				if ( disableBreakpoints.includes( breakpoint ) ) {
					const disableObj = {};
					disableObj[ bootstrapBreakpoints[ breakpoint ] ] = {
						destroy: true,
					};

					config = merge( config, {
						breakpoints: disableObj,
					} );

					disableFlag = true;
				} else if ( disableFlag === true ) {
					const disableObj = {};
					disableObj[ bootstrapBreakpoints[ breakpoint ] ] = {
						destroy: false,
					};

					config = merge( config, {
						breakpoints: disableObj,
					} );

					disableFlag = false;
				}
			}
		}
		switch(configAttr) {
			case "logos-carousel": {
				new Splide( splide, config ).mount( { Grid } );
				break;
			}

			default: {
				new Splide( splide, config ).mount();
				break;
			}
		}

		const splider = new Splide(splide, config).mount();
		mainSplider = splider;

		const hasThumbnailEnable = splide.getAttribute('data-thumbnail');

		// addon
		if (hasThumbnailEnable === 'enable') {
			const thumbnailsContainer = splide.parentNode.querySelector(
				'#thumbnail-carousel'
			);

			const thumbnails = new Splide(
				thumbnailsContainer,
				config?.thumbnailSettings
			);

			thumbnailSplider = thumbnails;

			thumbnails.mount();
			splider.sync(thumbnails);
		}

	} );
}

export { mainSplider, thumbnailSplider };

document.addEventListener( 'DOMContentLoaded', () => {
	initializeSplide();
} );
